@font-face {
  font-family: "satoshi";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Regular.otf) format("woff");
}

@font-face {
  font-family: "SatoshiBold";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Medium.otf) format("woff");
}

@font-face {
  font-family: "SatoshiBolder";
  src: local("MyFont"), url(./Fonts/OTF/Satoshi-Bold.otf) format("woff");
}

body {
  font-family: "satoshi" !important;
}

.form-input {
  color: "#5B2E4F";
  border-radius: 6px;
  border: 0.17px solid rgba(91, 46, 79, 1) !important;
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f);
  padding: 15px 10px;
  width: 100%;
  font-size: 13px;
}


.playstore{
  background-color: #000;
  font-family: 'Satoshi';
  font-size: 10 !important;
}

.smallplaystore{
  background-color: #000;
  font-family: 'Satoshi';
  font-size: 5 !important;
}
.faqs {
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

@media only screen and (max-width: 600px) {
  .button-store-name {
    font-size: 14px;
    font-weight: bold;
}

.button-title {
  font-size: 10px;
}
}

.disabled-input {
  color: "#5B2E4F";
  border-radius: 6px;
  border: 0.17px solid rgba(91, 46, 79, 1) !important;
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #4a535d, #4a535d);
  padding: 15px 10px;
  width: 100%;
  font-size: 13px;
}

.form-input-phonenumber {
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f);
  font-size: 13px;
  width: 100%;
}

.form-input-phonenumber:focus {
  outline: none;
  /* This will remove the default focus outline */
}

.form-textarea {
  color: "#5B2E4F";
  border-radius: 6px;
  border: 0.17px solid rgba(91, 46, 79, 1);
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f) !important;
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100px;
  font-size: 13px;
}

.optionFields {
  color: #33343d;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.8px;
}

.form-input::placeholder {
  color: #33343d;
  font-weight: 500;
  font-size: 13px;
  opacity: 0.5;
}

.form-input:focus,
.form-textarea {
  outline: none;
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f);
  /* This will remove the default focus outline */
}

.form-input {
  position: relative;
}

.form-input::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0.17px solid #5b2e4f;
  pointer-events: none;
  /* This ensures the pseudo-element doesn't interfere with interactions */
}

.upload-input {
  background-color: #33343d;
  position: absolute;
  top: 50px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-input-hidden {
  background-color: #33343d;
  position: absolute;
  top: 10px;
  width: 100%;
  opacity: 0;
}

/* For Chrome and Safari */

.form-input:-webkit-autofill {
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f);
  border: 1px solid #ccc;
}

.form-textarea:-webkit-autofill {
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f);
  border: 1px solid #ccc;
}

.form-input-phonenumber:-webkit-autofill {
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f) !important;
}

.custom-spinner {
  width: 24px;
  /* Set width and height to create a spinner of the desired size */
  height: 24px;
  border-radius: 50%;
  /* Set border-radius to create a circle */

  /* Apply the conic-gradient as the boxShadow */
  /* Ensure to adjust the colors and angle according to your desired gradient */
  /* The last value in rgba should be set to 0 for transparency */
  /* You can also adjust the background-size to control the gradient size */
  box-shadow: conic-gradient(
      from 180deg at 50% 50%,
      #540a45 0deg,
      rgba(182, 0, 130, 0.568421) 180deg,
      rgba(84, 10, 69, 0) 360deg
    )
    0 0 0 3px transparent !important;
}

.uploadBtn {
  background: linear-gradient(0deg, #f8f1f6, #f8f1f6),
    linear-gradient(0deg, #5b2e4f, #5b2e4f) !important;
}

/* Adjust the size of the spinner here */
.spinner {
  margin-top: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #540a45;
  /* Set the first color of the gradient */
  border-right-color: rgba(182, 0, 130, 0.568421);
  /* Set the second color of the gradient */
  animation: spin 0.6s linear infinite;
  /* Add a spinning animation */
}

.disabledInput {
  background-color: #f2f2f2;
  color: #888888;
  /* Add other styles as needed */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.floating-arrow {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #5b2e4f;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.floating-arrow:hover {
  background-color: #5b2e4f;
}

/********** Onboarding Splash Screen **********/
.onboarding {
  background-color: #f8f1f6;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-image: url(../src/assets/hervest-bg.svg);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.splash-logo {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  margin-bottom: 200px;
}
